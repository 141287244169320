import restClient from "./api";

let baseURLCaseService= 'http://148.251.88.151:808/portal-service';

class CaseService 
{

    /*
        {
            "files":["file1","file2"],
            "extensions":{"id":"extensionid-1","id":"extensionid-2"},
            "details":"Some details",
            "name":"Some name",
            "sex":"sex",
            "modality":"modality",
            "birthdate":"128555478",
            "patient_id":"112-3"
        }
    */
    createCase(json) 
    {
        return restClient.post(baseURLCaseService+'/create-case',json);
    }

    /*
        {
            "id":"124",
            "details":"Some details",
            "name":"Some name",
            "sex":"sex",
            "modality":"modality",
            "birthdate":"128555478",
            "patient_id":"112-3"
        }
    */
    updateCase(json) 
    {
        return restClient.post(baseURLCaseService+'/update-case',json);
    }

  listCaseById(caseId) 
  {
    return restClient.post(baseURLCaseService+'/list-case-by-id',{id:caseId});
  }

    /*
      {
        "search_string":"124",
        "details":"Some details to search",
        "name":"Some name",
        "sex":"sex",
        "modality":"modality",
        "birthdate":"128555478",
        "patient_id":"112-3",
        "limit_start":0,
        "limit_count": 20,
        "count_only": false
      }
  */
  listCases(json) 
  {
    return restClient.post(baseURLCaseService+'/list-cases',json);
  }
}

export default new CaseService();